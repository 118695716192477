<template>
  <div class="relative">
    <div class="flex items-center gap-4 w-full">
      <button
        @click="isModalOpen = true"
        class="px-2 bg-white border border-gray-300 rounded-md text-sm text-start text-gray-900 py-2 hover:bg-gray-50 min-w-[18.5rem]"
      >
        {{ getSelectedTextureName ? getSelectedTextureName : 'Choose Texture' }}
      </button>
    </div>
    <div
      v-if="isModalOpen"
      @click="isModalOpen = false"
      class="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 rounded-xl"
    >
      <div class="bg-white rounded-lg w-full max-w-3xl p-6">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-medium text-gray-500">
            {{ $t('templateCreation.textChooseTxt') }}
          </h3>
          <button
            @click="isModalOpen = false"
            class="text-gray-400 hover:text-gray-600"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="grid grid-cols-2 md:grid-cols-3 gap-4 h-64 overflow-y-scroll overflow-x-hidden p-2"
        >
          <div
            v-for="texture in textures"
            :key="texture.id"
            @click="selectTexture(texture)"
            class="relative cursor-pointer rounded-lg overflow-hidden border-2 transition-all duration-200 hover:shadow-lg"
            :class="{
              'border-blue-500': modelValue === texture.url,
              'border-transparent': modelValue !== texture.url,
            }"
          >
            <img
              :src="texture.url"
              :alt="texture.name"
              class="w-full h-48 object-cover"
            />
            <div
              class="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-2 text-center text-sm"
            >
              {{ texture.name }}
            </div>
          </div>
        </div>

        <!-- Footer avec boutons -->
        <div class="flex items-center gap-3 mt-5">
          <div class="grow"></div>
          <button
            @click="isModalOpen = false"
            class="bg-secondary text-white text-sm shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 ml-auto"
          >
            {{ $t('imageHandler.selectButton') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default:
      'https://myntfsid.mypinata.cloud/ipfs/bafybeigzxnh2rwpcjgyatlne3cywb4kkmumn3uvb4blihxqwkaonvwdjhy',
  },
});

const emit = defineEmits(['update:modelValue']);

const isModalOpen = ref(false);

const textures = [
  {
    id: 1,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafybeigzxnh2rwpcjgyatlne3cywb4kkmumn3uvb4blihxqwkaonvwdjhy',
    name: 'Texture 1',
  },
  {
    id: 2,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafybeihz5enny4n3q67pcrh5ddayuzew2wxofummldzsb5nutcllusp7nm',
    name: 'Texture 2',
  },
  {
    id: 3,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafkreighbwlqau4ni7e2gdqkezjnvtvycpubw6y2tmild5yuljhob5v7cq',
    name: 'Texture 3',
  },
  {
    id: 4,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafkreic5x4gzbizswog2in6wsgwh7hs4ycaskt3ah33be5ldhed4s77ylu',
    name: 'Texture 4',
  },
  {
    id: 5,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafkreigb7noy6uq4hfpflfrmop3dwotdz7wss7rzml3cxgzyxu3andoqqa',
    name: 'Texture 5',
  },
  {
    id: 6,
    url: 'https://myntfsid.mypinata.cloud/ipfs/bafybeihyv3cua77rwjvly3t3nrtyx7xs3q74e7bflk44pw4uvaxxjr6ypy',
    name: 'Texture 6',
  },
];

const getSelectedTextureName = computed(() => {
  const texture = textures.find((t) => t.url === props.modelValue);
  return texture ? texture.name : 'Choose Texture';
});

const selectTexture = (texture) => {
  emit('update:modelValue', texture.url);
  isModalOpen.value = false;
};
</script>
