<template>
  <div class="py-10 px-16">
    <input
      class="w-full !text-xl border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 mt-8 mb-2"
      :placeholder="$t('search.searchPlaceholder')"
      maxlength="50"
      v-model="badgeTemplateSearchTerms"
      ref="searchInput"
    />
    <hr class="border border-bottom mb-10" />
    <div class="pt-2 relative">
      <div
        v-for="templateType in [
          'Basic',
          'Community',
          'Participation',
          'Membership',
          'Role',
        ]"
        :key="templateType"
      >
        <p
          v-if="
            filteredTemplateList.filter((template) => {
              return template.data
                ? template.data.badgeCategory == templateType
                : false;
            }).length > 0
          "
          class="font-bold mb-2"
        >
          <icon :name="`badge-${templateType.toLowerCase()}`" class="mr-1" />{{
            templateTypeToLabel(templateType, i18n)
          }}
        </p>
        <div
          v-if="
            filteredTemplateList.filter((template) => {
              return template.data
                ? template.data.badgeCategory == templateType
                : false;
            }).length > 0
          "
          class="flex flex-wrap w-full gap-4 pb-3 mb-5"
        >
          <div
            v-for="(template, index) in filteredTemplateList.filter(
              (template) => {
                return template.data
                  ? template.data.badgeCategory == templateType
                  : false;
              }
            )"
            :key="template"
            class="rounded-2xl h-min"
          >
            <router-link
              :to="`/templates/${template.data.badgeCategory.toLowerCase()}?hash=${template.hash.replace(
                '0x',
                ''
              )}`"
            >
              <TemplatePreview
                v-if="template.data && currentIssuerInfo"
                :templateImage="template.data.image"
                :templateName="template.data.name"
                :templateLocation="
                  template.data.badgeCategory == 'Participation' ||
                  template.data.badgeCategory == 'Ticket'
                    ? template.data.eventDetails.location.split(', ')[0]
                      ? template.data.eventDetails.location.split(', ')[0]
                      : $t('templateDisplay.virtualTxt')
                    : null
                "
                :templateStartDate="
                  template.data.badgeCategory == 'Participation' ||
                  template.data.badgeCategory == 'Ticket'
                    ? template.data.eventDetails.startDate
                    : null
                "
                :templateEndDate="
                  template.data.badgeCategory == 'Participation' ||
                  template.data.badgeCategory == 'Ticket'
                    ? template.data.eventDetails.endDate
                    : null
                "
                :templateHexColor="
                  template.data.badgeCategory == 'Role'
                    ? template.data.hexColorRef
                    : null
                "
                :templateThemeBgColor="
                  template.data.badgeTheme
                    ? template.data.badgeTheme.backgroundCardColor
                    : null
                "
                :templateThemeTextColor="
                  template.data.badgeTheme
                    ? template.data.badgeTheme.textColor
                    : null
                "
                :templateThemeEffect="
                  template.data.badgeTheme
                    ? template.data.badgeTheme.cardEffect
                    : null
                "
                :templateThemeTexture="
                  template.data.badgeTheme
                    ? template.data.badgeTheme.textureImageURL
                    : null
                "
                :templateThemeIsLogoBack="
                  template.data.badgeTheme
                    ? template.data.badgeTheme.isLogoBack
                    : null
                "
                :issuerName="currentIssuerInfo.name"
                :issuerImage="currentIssuerInfo.profileImage"
                :type="template.data.badgeCategory"
                :templateContractState="template.contractState"
                :formatSm="true"
                class="cursor-pointer"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import TemplatePreview from '../components/templates/TemplatePreview.vue';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const badgeTemplateSearchTerms = ref(null);
    const basicTemplateList = computed(
      () => store.getters.getBasicTemplateList
    );
    const communityTemplateList = computed(
      () => store.getters.getCommunityTemplateList
    );
    const participationTemplateList = computed(
      () => store.getters.getParticipationTemplateList
    );
    const ticketTemplateList = computed(
      () => store.getters.getTicketTemplateList
    );
    const membershipTemplateList = computed(
      () => store.getters.getMembershipTemplateList
    );
    const roleTemplateList = computed(() => store.getters.getRoleTemplateList);
    const templateTypeToLabel = ref(utils.templateTypeToLabel);
    const i18n = ref(useI18n());
    const searchInput = ref(null);

    const fullTemplateList = computed(() => {
      if (
        basicTemplateList.value &&
        communityTemplateList.value &&
        participationTemplateList.value &&
        ticketTemplateList.value &&
        membershipTemplateList.value &&
        roleTemplateList.value
      )
        return [
          ...basicTemplateList.value,
          ...communityTemplateList.value,
          ...participationTemplateList.value,
          ...ticketTemplateList.value,
          ...membershipTemplateList.value,
          ...roleTemplateList.value,
        ];
      else return [];
    });

    const filteredTemplateList = computed(() => {
      const list = [];
      if (fullTemplateList.value.length == 0) return list;
      if (
        !badgeTemplateSearchTerms.value ||
        badgeTemplateSearchTerms.value.length < 1
      )
        return list;

      for (var template of fullTemplateList.value) {
        if (
          (badgeTemplateSearchTerms.value &&
            badgeTemplateSearchTerms.value.length > 0 &&
            template.data.name
              .toLowerCase()
              .indexOf(badgeTemplateSearchTerms.value.toLowerCase()) != -1) ||
          !badgeTemplateSearchTerms.value ||
          badgeTemplateSearchTerms.value.length < 1
        ) {
          list.push(template);
        }
      }

      while (list.length < 4) {
        list.push({});
      }

      return list;
    });

    onMounted(() => {
      searchInput.value.focus();
    });

    return {
      badgeTemplateSearchTerms,
      filteredTemplateList,
      currentIssuerInfo,
      templateTypeToLabel,
      i18n,
      searchInput,
    };
  },
  components: {
    TemplatePreview,
  },
};
</script>
