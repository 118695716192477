<template>
  <div>
    <div
      class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-[100]"
      :class="large ? 'w-modal-md' : 'w-modal-sm'"
    >
      <div
        v-if="!noCloseOption && !absoluteClose"
        @click="closeModal"
        class="text-right text-gray-400 cursor-pointer leading-none"
      >
        <icon name="cross" class="text-sm fill-gray-400" />
      </div>
      <div
        v-if="!noCloseOption && absoluteClose"
        @click="closeModal"
        class="relative"
      >
        <div
          class="absolute right-0 cursor-pointer"
          :class="{ 'p-5': !absoluteClose, 'px-3 py-1': absoluteClose }"
        >
          <icon name="cross" class="text-sm fill-gray-400" />
        </div>
      </div>
      <div :class="{ 'p-5': !absoluteClose, 'px-3 py-1': absoluteClose }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { noCloseOption: Boolean, large: Boolean, absoluteClose: Boolean },
  setup(props, context) {
    function closeModal() {
      context.emit('close');
    }

    return { closeModal };
  },
};
</script>
